import React, { PureComponent } from 'react'

import Cookies from 'mixins/Cookies'
import Fetch from 'mixins/Fetch'

const idioma = Cookies.idioma();
const idiomas = require(`i18n/idiomas`);

export default class Idioma extends PureComponent {
  state = {
    idioma: Cookies.idioma()
  }

  componentDidMount(){
    $(this.refs.idiomas).dropdown({
      onChange: this.change
    }).dropdown('set selected', idioma)
  }

  change = (value) => {
    if(this.state.idioma == value) return

    /*let { data } = await Fetch({
      datos: { idioma: value },
      metodo: 'POST',
      url: '/a/idioma'
    })*/
		/*Ajax.send({
			datos: {
				idioma: idioma.value
			},
			metodo: 'POST',
			url: auth + '/idioma'
		}, (res, status) => {
			if(res !== 'err' && status === 200) {
        this.state.idioma = value
				location.reload();
			}
		});*/
	}

	render(){
		return (
      <div class="ui dropdown" ref="idiomas">
        <input type="hidden" name="gender" />

        <div class="default text">Seleccionar Idioma</div>
        <i class="dropdown icon"></i>

        <div class="menu">
          { Object.keys(idiomas).map( (item, pos) => (
            <div class='item' data-value={item} key={ pos }>
              { idiomas[item] }
            </div>
          )) }
        </div>
      </div>
		)
	}
}
