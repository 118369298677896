class Historial {
	constructor() {
		this._event = document.createEvent('Event')
		this._event.initEvent('historialstate', true, true)
	}

	pushState() {
		const { origin, pathname } = location

		const uri = origin + pathname
		const route = arguments[2]

		if (route.indexOf(origin) === 0 && uri === route) {
			return
		}

		if (route[0] === '/' && pathname === route) {
			return
		}

		const split = pathname.split('/')

		if (route[0] !== '/' && split[split.length - 1] === route) {
			return
		}

		history.pushState(arguments[0], arguments[1], arguments[2])
		window.dispatchEvent(this._event)
	}

	replaceState() {
		const { origin, pathname } = location

		const uri = origin + pathname
		const route = arguments[2]

		if (route.indexOf(origin) === 0 && uri === route) {
			return
		}

		if (route[0] === '/' && pathname === route) {
			return
		}

		const split = pathname.split('/')

		if (route[0] !== '/' && split[split.length - 1] === route) {
			return
		}

		history.replaceState(arguments[0], arguments[1], arguments[2])
		window.dispatchEvent(this._event)
	}
}

window.historial = new Historial()
