String.prototype.trim = function () {
	return this.replace(/^\s+|\s+$/g, '')
}

String.prototype.trimAll = function () {
	return this.replace(/^(\s)*/, '')
		.replace(/(\s)*$/, '')
		.replace(/(\s+)/g, ' ')
}

String.prototype.primeraMayuscula = function () {
	return this.charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.numberWithCommas = function () {
	const parts = this.split('.')
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

	return parts.join('.')
}

String.prototype.esLink = function () {
	const cabezera = ['https://', 'http://', 'ftp://']
	const cola = [
		'.com',
		'.gov',
		'.org',
		'.net',
		'.biz',
		'.info',
		'.name',
		'.travel'
	]
	let correcto = true

	if (this.split(' ').length === 1 && this !== '') {
		for (let i = 0; i < this.length; i++) {
			const c = i

			for (let j = 0; j < cabezera.length; j++) {
				if (cabezera[j] === this.slice(i, i + cabezera[j].length)) {
					i += cabezera[j].length
				}
			}

			let validar = /[\?\/\#\.\-]|\b/
			let detener = false
			let frase = ''

			while (
				!this.charAt(i).match(/\s/) &&
				this.charAt(i) !== '' &&
				this.charAt(i).match(validar)
			) {
				if (this.charAt(i).match(/[\?\/\#]/)) {
					detener = true
					validar = /\S/
				}

				if (!detener) {
					frase += this.charAt(i)
				}
				i++
			}

			const validacion = this.slice(c, i).match(
				/^([A-Za-z0-9]{1}\S+\.{1,2}[A-Za-z0-9]{2,9})([\?\/\#]{1}\S*)?$/
			)
			const separacion = validacion ? frase.split('.') : []

			for (let j = 0; j < separacion.length; j++) {
				if (separacion[j] === '') {
					correcto = false
					j = separacion.length
				}
			}

			const separacion1 = validacion ? frase.split('-') : []

			for (let j = separacion1.length - 1; j >= 0; j--) {
				if (
					separacion1[j] === '' ||
					separacion1[j].charAt(0) === '.' ||
					separacion1[j].charAt(separacion1[j].length - 1) === '.'
				) {
					correcto = false
					j = 0
				}
			}

			if (correcto) {
				if (separacion.length > 0) {
					if (separacion[separacion.length - 1].length > 2) {
						const dominio = '.' + separacion[separacion.length - 1]
						correcto = false
						for (let j = 0; j < cola.length; j++) {
							if (cola[j] === dominio || dominio.length === 4) {
								correcto = true
								j = cola.length
							}
						}
					}
				} else {
					correcto = false
				}
			}
		}
	} else {
		correcto = false
	}

	return correcto
}

String.prototype.buscar = function (expresion, inicio) {
	const tipo = typeof expresion
	const type =
		Object.prototype.toString.call(expresion).match(/^\[object\s(.*)\]$/)[1] ||
		''
	let texto = this

	if (tipo === 'string') {
		expresion = expresion.trimAll().toLowerCase()
		texto = texto.toLowerCase()
	} else if (tipo === 'object' && type !== 'Array') {
		inicio = expresion.inicio ? expresion.inicio : 0

		if (expresion.mayusculas !== true) {
			expresion = expresion.toLowerCase()
			texto = texto.toLowerCase()
		}

		expresion = expresion.patron.trimAll()
	} else {
		console.error('Tipo de dato no valido')
		return null
	}

	const m = expresion.length - 1
	const n = texto.length - 1
	let j = m

	inicio = inicio || m

	while (inicio <= n && j >= 0) {
		if (texto[inicio - (m - j)] === expresion[j]) {
			j--
		} else {
			inicio = inicio + (m - j) + 1
			j = m
		}
	}

	if (j === m) {
		return null
	}

	return {
		a: inicio - m,
		b: inicio + 1
	}
}

String.prototype.buscarAll = function (expresion, inicio) {
	let pos = 0
	let resultados
	const temporal = []

	if (inicio) {
		pos = inicio
	} else if (expresion.inicio) {
		pos = expresion.inicio
	}

	do {
		resultados = this.buscar(expresion, pos)

		if (resultados) {
			pos = resultados.b
			temporal.push(resultados)
		}
	} while (resultados)

	return temporal
}

String.prototype.buscarPalabra = function (expresion, inicio) {
	const tipo = typeof expresion
	const type =
		Object.prototype.toString.call(expresion).match(/^\[object\s(.*)\]$/)[1] ||
		''
	let patron = ''

	if (tipo === 'string') {
		patron = expresion.trimAll().toLowerCase()
	} else if (tipo === 'object' && type !== 'Array') {
		patron = expresion.patron.trimAll()
	}

	const palabras = patron.split(' ').filter(Boolean)
	const temporal = {}
	let resultados = []

	for (let i = 0; i < palabras.length; i++) {
		const palabra = palabras[i]

		if (tipo === 'string') {
			expresion = palabra
		} else if (tipo === 'object' && type !== 'Array') {
			expresion.patron = palabra
		}

		resultados = this.buscarAll(expresion, inicio)

		if (resultados && resultados.length > 0) {
			temporal[palabra] = resultados
		}
	}

	return temporal
}

String.prototype.cleanString = function () {
	let cadena = this

	// Definimos los caracteres que queremos eliminar
	const specialChars = '!@#$^&%*()+=-[]/{}|:<>?,.'

	// Los eliminamos todos
	for (let i = 0; i < specialChars.length; i++) {
		cadena = cadena.replace(new RegExp('\\' + specialChars[i], 'gi'), '')
	}

	// Lo queremos devolver limpio en minusculas
	cadena = cadena.toLowerCase()

	// Quitamos espacios y los sustituimos por _ porque nos gusta mas asi
	cadena = cadena.replace(/ /g, '-')

	// Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
	cadena = cadena.replace(/á/gi, 'a')
	cadena = cadena.replace(/é/gi, 'e')
	cadena = cadena.replace(/í/gi, 'i')
	cadena = cadena.replace(/ó/gi, 'o')
	cadena = cadena.replace(/ú/gi, 'u')
	cadena = cadena.replace(/ñ/gi, 'n')

	return cadena
}
