const toType = function (obj) {
	if (typeof obj === 'undefined') {
		return 'undefined'
	}

	if (obj === null) {
		return 'null'
	}

	const type = Object.prototype.toString.call(obj).match(/^\[object\s(.*)\]$/)[1] || ''

	switch (type) {
	case 'Number':
		if (isNaN(obj)) {
			return 'nan'
		} else {
			return 'number'
		}

	case 'String':
	case 'Boolean':
	case 'Array':
	case 'Date':
	case 'RegExp':
	case 'Function':
		return type.toLowerCase()
	}

	if (typeof obj === 'object') {
		return 'object'
	}

	return undefined
}

window.toType = toType
