import listaIdiomas from 'i18n/idiomas';

const Cookies = {

	get(cookie) {
		let cookies = document.cookie.split(';');
		let nombre = cookie + '=';

		for(let i = 0; i < cookies.length; i++) {
			let c = cookies[i];

			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}

			if (c.indexOf(nombre) === 0) {
				return c.substring(nombre.length, c.length);
			}
		}

		return '';
	},

	set(cookie, value) {
		document.cookie = `${cookie}=${value}`;
	},

	idioma() {
		let idioma = this.get('idioma');
		let defaults = 'es';

		if(!idioma || !listaIdiomas[idioma]){
			return defaults;
		}

		return idioma;
	},

	defecto(cookie, valor) {
		cookie = this.get(cookie);

		if(cookie !== valor){
			return valor;
		}

		return cookie;
	}

};

export default Cookies;
