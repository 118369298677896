import './style.styl'

window.addEventListener('load', () => {
	// Activa todas las lista desplegables.
	if ($) {
		$('.dropdown').dropdown()
	}

	// Se obtienen los tag´s que permiten cerrar la sesión de usuario.
	let salir = document.querySelectorAll('header [href="/c/salir"]')

	for (let i = 0; i < salir.length; i++) {
		let item = salir[i]

		// Ejecuta una solicitud de cierre de sesión al dar click en el tag
		item.addEventListener(
			'click',
			(ev) => {
				ev.preventDefault()
				fetch('/c/salir', { method: 'POST' }).then(() => location.reload())
			},
			false
		)
	}
})
