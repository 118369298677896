import { render, unmount } from './render'

class router {
	constructor (contenedor, route) {
		this._actual = 'init'
		this._contenedor = contenedor
		this._observers = []
		this._params = {}
		this._root = { unmount: () => {} }
		this._route = route || location.pathname
		this._vistas = {}

		const { compiler } = this

		window.addEventListener('hashchange', compiler, false)
		window.addEventListener('historialstate', compiler, false)
		window.addEventListener('load', compiler, false)
		window.addEventListener('popstate', compiler, false)
	}

	set href (src) {
		if (src === '' || src === '/') src = ''
		else src = src.substr(0, 1) === '/' ? src : `/${src}`

		historial.pushState(null, {}, `${this._route}${src}${location.hash}`)
	}

	get href () {
		return this._href
	}

	get params () {
		return this._params
	}

	get route () {
		return this._route
	}

	set route (route) {
		this._route = route
	}

	get vistas () {
		return this._vistas
	}

	set vistas (vistas) {
		let route = this._route

		for (const key in vistas) {
			const split = route.split(key)
			if (key !== '/' && split[0]) route = split[0]
		}

		this._route = route
		this._vistas = vistas
	}

	register (observer) {
		this._observers.push(observer)
	}

	compiler = () => {
		const { _actual, _contenedor, _vistas, _route, _observers } = this
		let { origin, href, hash, pathname } = location

		href = pathname.replace(hash, '')
		href = href.replace(origin, '')
		href = href.replace(_route, '')

		let params = {}

		for (const key in _vistas) {
			const keySplit = key.split('/')
			const hrefSplit = href.split('/')

			if (keySplit.length === hrefSplit.length) {
				let diferent = false

				for (let i = 0; i < keySplit.length; i++) {
					if (keySplit[i] === hrefSplit[i]) continue
					else if (keySplit[i].indexOf(':') === 0) params[keySplit[i].slice(1)] = hrefSplit[i]
					else diferent = true
				}

				if (!diferent) {
					href = key
					break
				}

				params = {}
			}
		}

		if (href === _actual) return
		if (href === '') href = '/'

		let vista = _vistas[href] || _vistas[hash]

		if (!vista) {
			if (_actual !== 'init') { return }

			vista = _vistas['/'] || _vistas.defaults
			href = '/'
		}

		this._actual = href
		this._params = params

		for (let i = 0; i < _observers.length; i++) {
			_observers[i](href, params)
		}

		try {
			this._root.unmount()
		} catch (err) {
			console.error(err)
		}

		this._root = render(_contenedor, vista)
		return
	}
}

window.router = router

export default router
