import React from 'react'
import { createRoot } from 'react-dom/client'

const Components = []

const getComponent = (tag) => {
	let component = null
	let position = -1

	for (let i = 0, l = Components.length; i < l; i++) {
		if (tag === Components[i].tag) {
			component = Components[i].tag
			position = i

			break
		}
	}

	return {
		component,
		position
	}
}

const Render = function (tag, componente) {
	if (tag) {
		if (typeof tag === 'string') {
			tag = document.querySelectorAll(tag)
		} else if (tag.length === undefined) {
			tag = [tag]
		}

		for (let i = 0; i < tag.length; i++) {
			const c = tag[i]
			const attributes = {}
			let component = componente

			const atts = c.attributes

			if (atts.length > 0) {
				for (let j = 0; j < atts.length; j++) {
					attributes[atts[j].name] = c.getAttribute(atts[j].name)
				}
			}

			if (attributes !== {}) {
				component = React.cloneElement(componente, attributes)
			}

			const root = createRoot(tag[i])
			root.render(component)

			const { position } = getComponent(tag[i])

			if (Components[position]) {
				Components[position].root = root
			}

			return root
		}
	}
}

const load = function () {
	const nodeList = Object.values(Components)

	for (let i = 0; i < nodeList.length; i++) {
		const { tag, componente } = nodeList[i]
		Render(tag, componente)
	}
}

window.addEventListener('load', load)

export function render(tag, componente) {
	return Render(tag, componente)
}

export default function add(tag, componente) {
	const isNodeList = NodeList.prototype.isPrototypeOf(tag)

	if (!isNodeList) tag = [tag]

	for (let i = 0, l = tag.length; i < l; i++) {
		const { component, position } = getComponent(tag[i])

		if (component) {
			Components[position] = componente
			break
		}

		Components.push({
			componente: componente,
			tag: tag[i]
		})
	}
}
