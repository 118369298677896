Array.prototype.enArray = function (element, sensitivity) {
	if (sensitivity === false && typeof element === 'string') {
		element = element.toLowerCase()
	}

	for (let i = 0; i < this.length; i++) {
		let compare = this[i]

		if (sensitivity === false && typeof compare === 'string') {
			compare = compare.toLowerCase()
		}

		if (compare === element) {
			return true
		}
	}

	return false
}

Array.prototype.enJsonArray = function (props) {
	let element = props.element
	const attr = props.attr
	const sensitivity = props.sensitivity

	if (!attr) {
		return this.enArray(element, sensitivity)
	}

	if (sensitivity === false && typeof element === 'string') {
		element = element.toLowerCase()
	}

	for (let i = 0; i < this.length; i++) {
		let compare = this[i][attr]

		if (sensitivity === false && typeof compare === 'string') {
			compare = compare.toLowerCase()
		}

		if (compare === element) {
			return true
		}
	}

	return false
}

Array.prototype.itemJson = function (props) {
	const { element, attr, json } = props

	if (!attr || !json) {
		return false
	}

	for (let i = 0; i < this.length; i++) {
		const object = this[i][json]

		if (!object || typeof object !== 'object') {
			return false
		}

		if (this[i][json][attr] === element) {
			return this[i]
		}
	}

	return false
}

Array.prototype.getLista = function (prop) {
	let array
	let attr = null
	let encontrado = false
	let inv = false
	let i
	let item
	let itemE
	let itemD
	let j
	const lista = []

	if (prop) {
		array = prop.array
		attr = prop.attr

		if (prop.inv) {
			inv = prop.inv
		}
	}

	for (i = 0; i < this.length; i++) {
		itemD = this[i]

		for (j = 0; j < array.length; j++) {
			if (attr && prop.only) {
				item = array[j]
				itemE = itemD[attr]
			} else if (attr && prop.onlyInv) {
				item = array[j][attr]
				itemE = itemD
			} else if (attr) {
				item = array[j][attr]
				itemE = itemD[attr]
			} else {
				item = array[j]
				itemE = itemD
			}

			if (itemE === item) {
				// lista.push(item);
				encontrado = true
				break
			}
		}

		if (encontrado && !inv) {
			if (prop.onlyAttr) {
				lista.push(itemD[attr])
			} else {
				lista.push(itemD)
			}
		} else if (!encontrado && inv) {
			if (prop.onlyAttr) {
				lista.push(itemD[attr])
			} else {
				lista.push(itemD)
			}
		}

		encontrado = false
	}

	return lista
}

Array.prototype.getNoListaAttrSolo = function (array, attr) {
	const lista = []
	let item
	let encontrado = false

	for (let i = 0; i < this.length; i++) {
		for (let j = 0; j < array.length; j++) {
			item = array[j]

			if (this[i][attr] === item) {
				encontrado = true
				break
			}
		}

		if (!encontrado) {
			lista.push(item)
		}
	}

	return lista
}

Array.prototype.ordenar = function (args, extra) {
	const attr = args.attr
	let parametro = args.parametro
	let orden = args.orden
	let fecha = false

	if (typeof args === 'string') {
		parametro = args

		if (extra) {
			orden = extra
		}
	} else {
		if (args.fecha) {
			fecha = args.fecha
		}
	}

	if (orden === 'desc') {
		this.sort(function (a, b) {
			if (attr) {
				a = a[parametro][attr]
				b = b[parametro][attr]
			} else {
				a = a[parametro]
				b = b[parametro]
			}

			if (fecha) {
				return new Date(b).getTime() - new Date(a).getTime()
			} else if (typeof a === 'string') {
				return (a < b) - (a > b)
			} else if (typeof a === 'number') {
				return parseFloat(b) - parseFloat(a)
			}

			return a
		})
	} else {
		this.sort(function (a, b) {
			if (attr) {
				a = a[parametro][attr]
				b = b[parametro][attr]
			} else {
				a = a[parametro]
				b = b[parametro]
			}

			if (fecha) {
				return new Date(a).getTime() - new Date(b).getTime()
			} else if (typeof a === 'string') {
				return (a > b) - (a < b)
			} else if (typeof a === 'number') {
				return parseFloat(a) - parseFloat(b)
			}

			return a
		})
	}
}

Array.prototype.iterar = function (callback) {
	let i = 0

	let funcEnd = null
	let run = null

	const next = (nuevoItem) => {
		const item = this[i]

		if (nuevoItem) {
			this[i - 1] = nuevoItem
		}

		if (item) {
			i++
			return run(item, next.bind(this), i - 1)
		}

		// setTimeout(() => {
		if (typeof funcEnd === 'function') {
			return funcEnd()
		}
		// }, 10);
	}

	run = callback.bind(this)
	next()

	return {
		end: (funcion) => {
			funcEnd = funcion.bind(this)
		}
	}
}

Array.prototype.random = function (cant, callback) {
	const nuevaLista = []

	for (let i = 0; i < cant; i++) {
		const random = Math.floor(Math.random() * this.length)
		const item = this[random]

		nuevaLista.push(item)
		this.splice(random, 1)
	}

	return callback(nuevaLista)
}

Array.prototype.randomUnique = function () {
	const random = Math.floor(Math.random() * this.length)
	const item = this[random]

	return item
}

Array.prototype.move = function (oldIndex, newIndex) {
	while (oldIndex < 0) {
		oldIndex += this.length
	}

	while (newIndex < 0) {
		newIndex += this.length
	}

	this.splice(newIndex, 0, this.splice(oldIndex, 1)[0])

	return this
}
